import React from "react";
import LoginSlider from "@components/login-swiper/index";
export default function Layout(props: any) {
  return (
    <>
      <section className="login-container">
        <div className="main-wrapper-login d-flex">
          <LoginSlider />
          <div className="form-wrapper d-flex position-relative">
            {props.children}
          </div>
          <img
            src="/images/login-rightside.svg"
            className="login-right-container"
          />
        </div>
      </section>
    </>
  );
}
