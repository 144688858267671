import { NavLink } from "react-router-dom";
import LoginPageLyout from "@layouts/login";
interface SentEmailPageProps {}

const SentEmail: React.FunctionComponent<SentEmailPageProps> = (props) => {
	return (
		<LoginPageLyout>
			<div className="form-wrapper flex-1 d-flex position-relative">
				<a href="#0" className="logo position-absolute">
					<img src="images/logo.png" className="img-fluid" />
				</a>
				<div className="m-auto form-wrapper__form">
					<h2 className="form-wrapper__form-common-title form-wrapper__form-common-title--email">
						Email Sent
					</h2>
					<p className="form-wrapper__form-text-description">
						Check your inbox for instructions from us on how to reset your
						password.
					</p>
					<NavLink to={`/login`}>
						<div className="form-cta-button">
							<button className="form-cta-button__primary-btn form-cta-button__primary-btn--reset-btn">
								Ok
							</button>
						</div>
					</NavLink>
				</div>
			</div>
		</LoginPageLyout>
	);
};

export default SentEmail;
