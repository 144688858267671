import { useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import InputField from "@common/InputRuleFeild";
import LoginPageLyout from "@layouts/login";
import { adminForgotPassword } from "@services/auth.service";
import SimpleReactValidator from "simple-react-validator";
import { useParams, useNavigate } from "react-router-dom";
import { getValue } from "@utils/lodash";

interface ILoginPageProps {}

const LoginPage: React.FunctionComponent<ILoginPageProps> = (props: any) => {
  let navigate = useNavigate();
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const simpleValidator = useRef(new SimpleReactValidator());
  const [isLoading, setIsLoading] = useState(false);
  const [, forceUpdate] = useState(0);
  const [email, setEmail] = useState("");
  const [user_type] = useState("VENDOR");

  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        setIsLoading(true);
        let payload = {
          email: email,
          user_type: user_type,
        };
        let resp = await adminForgotPassword(payload);
        if (resp) {
          // console.log(resp);
          setIsLoading(false);
          toast.success(getValue(resp, "message", ""));
          navigate(`/sent-email`);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */
  return (
    <LoginPageLyout>
      <div className="form-wrapper flex-1 position-relative d-flex flex-column">
        {/* <a href="#0" className="logo position-absolute">
          <img src="/images/logo.png" className="img-fluid" />
        </a> */}
        <img src="/images/logo.png" className="signin-mobile-logo px-4 pt-4 " />
        
        <div className="m-auto form-wrapper__form">
          <NavLink to="/login">
            <a href="#0" className="back-btn">
              <img
                src="/images/back-arrow.svg"
                className="img-fluid  back-arrow"
              />
              Back
            </a>
          </NavLink>
          <h2>Password Reset</h2>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <InputField
              inputType="TEXT"
              id="email"
              name="email"
              value={email}
              onChange={(e: any) => setEmail(e.target.value)}
              placeholder="Enter Email"
              className="form-control"
              label="Email"
              validator={simpleValidator}
            />
          </div>
          <div className="form-cta-button">
            {isLoading ? (
              <button className="form-cta-button__primary-btn form-cta-button__primary-btn--reset-btn">
                Please wait...
              </button>
            ) : (
              <button
                className="form-cta-button__primary-btn form-cta-button__primary-btn--reset-btn"
                onClick={handleSubmit}
              >
                Get Reset Link
              </button>
            )}
          </div>
        </div>
      </div>
    </LoginPageLyout>
  );
};

export default LoginPage;
