import React, { useEffect, useState, useRef } from "react";
import Layout from "@layouts/withoutNaviagtion/withoutNavigationLayout";
import { getValue, setValue } from "@utils/lodash";
//import { adminGetUsers } from '@services/users.service';
//import moment from 'moment';
import { useNavigate, useParams } from "react-router-dom";
import NavigationBar from "@components/NotificationBar/index";
import { toast } from "react-toastify";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import PaginationPage from "@components/pagination/pagination";
import Notfound from "@components/Not-Found";
import Search from "@components/common/Search";
import SimpleReactValidator from "simple-react-validator";
import { getAllRoles } from "@services/roles.service";
import {
  adminCreateUser,
  adminGetUsers,
  adminResendInviteUsers,
  adminUpdateSpecificUser,
} from "@services/users.service";
import moment from "moment";
import countries from "@common/json/contry.json";
import Loader from "@components/common/Loader";
import { adminBulkUpdateStatus } from "@services/users.service";
import { QueryRequestHelper } from "@common/query-request-helper";
import ScrollableReactTable from "@components/ScrollableDatatable";
import DashboardLayout from "@layouts/dashboard/dashboard-layout";
import DeleteModal from "@common/popup/DeleteModal";
import InputField from "@common/InputRuleFeild";
import Switch from "@components/common/switch";
import "@components/common/switch/switch.scss";
import GearLoader from "@components/common/GearLoader";

interface IAdminUsersProps {}

const AdminUsers: React.FunctionComponent<IAdminUsersProps> = (props: any) => {
  let navigate = useNavigate();
  let orgParams = useParams();

  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */

  const [isLoading, setIsLoading] = React.useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [usersList, setUsersList] = React.useState<[]>([]);
  const [isFilterVisible, setFilterVisibility] = React.useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const handleModal = () => {
    setRequest({
      ...request,
      name: "",
      email: "",
      phone_no: "",
      is_super_user: false,
    });
    setIsOpen(!isOpen);
    setEdit(false);
  };

  const [isOpen1, setIsOpen1] = useState(false);
  const handleModal1 = () => {
    setIsOpen1(!isOpen1);
  };

  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  const [request, setRequest] = useState({
    name: "",
    email: "",
    phone_no: "",
    is_super_user: false,
  });
  const [rolesList, setRolesList] = useState([]);
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (Object.keys(params).length === 0) {
      getData();
    }
    getRolesList();
  }, []);

  useEffect(() => {
    getRolesList();
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (Object.keys(params).length > 0) {
      getData();
      if (params.page_no) {
        setPage_no(parseInt(params.page_no));
      }
      if (params.search_text) {
        setSearchInput(params.search_text);
        setShowSearchTextbox(true);
      }
      if (params.limit) {
        setLimit(parseInt(params.limit));
      }
    }
  }, [window.location.href]);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  const getData = async () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    let payload: any = {
      page_no: params.page_no ? params.page_no : 1,
      page_size: params.limit ? params.limit : limit,
      search_text: params.search_text,
      status: params.status,
      sort_by: params.sort_by,
    };
    let queryRequest = QueryRequestHelper(payload);
    try {
      setIsLoading(true);
      let resp = await adminGetUsers(queryRequest);
      if (resp) {
        setUsersList(
          getValue(resp, `data.users`, []).map((item: object) => ({
            ...item,
            createdAtFormatted: moment(getValue(item, `created_at`, "")).format(
              "DD MMM YYYY"
            ),
            status: getValue(item, `is_active`, "") ? "Active" : "Inactive",
            last_signed_inFormatted: getValue(item, `last_signed_in`, "")
              ? moment(getValue(item, `last_signed_in`, "")).format(
                  "DD MMM YYYY"
                )
              : "",
          }))
        );
        setIsLoading(false);
        setTotalCount(getValue(resp, `data.pagination.total`, 0));
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleChangeActiveStatus = async (status: string) => {
    try {
      let resp = await adminBulkUpdateStatus({
        ids: selectedIDs,
        is_active: status == "active" ? true : false,
      });
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        setSelectedIDs([]);
        getData();
      }
    } catch (error) {}
  };

  const getRolesList = async () => {
    // try {
    //   let payload = {};
    //   let queryRequest = QueryRequestHelper(payload);
    //   let resp = await getAllRoles(queryRequest);
    //   if (resp) {
    //     setRolesList(
    //       getValue(resp, `data.roles`, []).map((item: object) => {
    //         return {
    //           id: getValue(item, `id`, ""),
    //           value: getValue(item, `title`, ""),
    //           label: getValue(item, `title`, ""),
    //         };
    //       })
    //     );
    //   }
    // } catch (error) {}
  };

  const handleSubmit = async () => {
    let access_token = localStorage.getItem("accessToken");
    let port = window.location.origin.split("//")[1].split(":")[0];
    let getOriginString = port.split(".");
    const str = window.location.origin;
    const urls = str.split("://")[1];
    let domain = urls.split(".")[0];
    const source = urls.replace("vendor.", "");

    let mainSource =
      getOriginString[0] === "localhost"
        ? "bigappcompany.deep-travel.in"
        : source;
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        setSubmitLoading(true);
        let payload = request;
        if (edit) {
          let resp = await adminUpdateSpecificUser(editId, payload);
          if (resp) {
            setSubmitLoading(false);
            toast.success(getValue(resp, `message`, ""));
            handleModal();
            setEdit(false);
            setEditId("");
            // navigate(`/admin/users`);
            getData();
          } else {
            setSubmitLoading(false);
          }
        } else {
          let resp = await adminCreateUser(payload, mainSource);
          if (resp) {
            setSubmitLoading(false);
            toast.success(getValue(resp, `message`, ""));
            handleModal();
            // navigate(`/admin/users`);
            getData();
          } else {
            setSubmitLoading(false);
          }
        }
      } catch (error) {
        setSubmitLoading(false);
        setEdit(false);
        setEditId("");
      }
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Search section                               */
  /* -------------------------------------------------------------------------- */
  const [showSearchTextbox, setShowSearchTextbox] = useState(false);
  const [search_text, setSearchInput] = useState<any>("");

  const toggleSearch = () => {
    setShowSearchTextbox((prevState) => !prevState);
  };
  const toggleSearchClose = () => {
    setSearchInput("");
    toggleSearch();
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.search_text) {
      delete params.search_text;
      let payload: any = { ...params };
      let queryRequest = QueryRequestHelper(payload);
      navigate(`${window.location.pathname}?${queryRequest}`);
      getData();
    }
  };

  const handleChangeSearch = async (e: any) => {
    setSearchInput(e.target.value);
    let payload: any = {
      search_text: e.target.value,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
    if (!e.target.value) {
      getData();
    }
  };

  const [selectedIDs, setSelectedIDs] = useState<any>([]);
  const getTableState = (value: object) => {
    let selectedIndexList = Object.keys(
      getValue(value, `selectedRowIds`, {})
    ).map((item) => parseInt(item));
    let selectedIds =
      usersList.length > 0
        ? usersList.filter((_item: object, index: number) =>
            selectedIndexList.includes(index)
          )
        : [];

    if (selectedIds.length > 0) {
      setSelectedIDs(
        selectedIds.map((item: object) => getValue(item, `id`, ""))
      );
    } else {
      setSelectedIDs([]);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                                 Delete section                             */
  /* -------------------------------------------------------------------------- */

  const [deleteValue, setDeleteValue] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const handleDeleteFun = (id: string, value: string) => {
    setDeleteValue(value);
    setDeleteId(id);
    handleDelete();
  };
  const [isDelete, setIsDelete] = useState(false);
  const handleDelete = () => {
    setIsDelete(!isDelete);
  };

  const handleDeleteFunction = async () => {
    // try {
    //   let resp = await adminDeleteSpecificCheckpoint(deleteId);
    //   if (resp) {
    //     toast.success(getValue(resp, `message`, ""));
    //     handleDelete();
    //     getData();
    //   }
    // } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  const handleChangeSortBy = (value: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    params.sort_by = value;
    props.history.push({
      pathname: window.location.pathname,
      query: params,
    });
  };

  const handleChangeStatus = (value: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    params.status = value;
    props.history.push({
      pathname: window.location.pathname,
      query: params,
    });
  };
  const handleChange = (e: any) => {
    const userInput = e.target.value;
    const regex = /^[0-9]*$/; // Regular expression to allow only digits and a single decimal point
    if (regex.test(userInput)) {
      return userInput;
    } else {
      return userInput ? userInput.replace(/[^0-9]/g, "") : "";
    }
  };
  const handleChangeText = (e: any) => {
    const { name, value } = e.target;
    setRequest({
      ...request,
      [name]: value,
    });
  };

  const handleTimeZone = (value: any) => {
    setValue(request, "user_timezone", value);
    setRequest({ ...request });
  };

  const handleChangeCountryCode = (e: any) => {
    setValue(request, "mobile_area_code", e);
    setRequest({ ...request });
  };

  const handleChangeRole = (e: any) => {
    setValue(request, "roles", e);
    setRequest({ ...request });
  };

  /* -------------------------------------------------------------------------- */
  /*                               Pagination section                           */
  /* -------------------------------------------------------------------------- */

  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page_no, setPage_no] = useState(1);

  const handleChangePagination = (page_no: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    let payload = {
      ...params,
      page_no: page_no,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  const handleChangeLimit = (limit: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    let payload: any = {
      ...params,
      limit: limit,
      page_no: "1",
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  const handleEdit = (obj: object) => {
    console.log(obj);

    setRequest({
      ...request,
      name: getValue(obj, `name`, ""),
      email: getValue(obj, `email`, ""),
      phone_no: getValue(obj, `phone_no`, ""),
      is_super_user: getValue(obj, `is_super_user`, ""),
    });
    setEditId(getValue(obj, `id`, ""));
    setEdit(true);
    setIsOpen(!isOpen);
  };

  const rowClick = (obj: object) => {
    handleEdit(getValue(obj, `original`, {}));
  };

  const rowClickEmpty = () => {};

  const handleResendInvite = async (e: any, id: string) => {
    e.stopPropagation();
    try {
      let resp = await adminResendInviteUsers(id);
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        setSelectedIDs([]);
        // getData();
      }
    } catch (error) {}
  };

  const [edit, setEdit] = useState(false);
  const [editId, setEditId] = useState("");
  const getTrProps = (rowInfo: any) => {
    if (getValue(rowInfo, `id`, "")) {
      return {
        style: {
          //   background:  'red',
          //   color: 'black'
        },
      };
    }
    return {};
  };
  const columns = React.useMemo(
    () => [
      {
        Header: "User ID",
        accessor: "email",
        width: 250,
        Cell: ({ row }: any) => {
          return (
            <p
              className="color_primary small_text__14"
              onClick={() => handleEdit(row.original)}
            >
              {row.values.email}
            </p>
          );
        },
      },
      {
        Header: "Name",
        accessor: "name",
        width: 250,
      },
      {
        Header: "Mobile Number",
        accessor: "phone_no",
        width: 250,
      },
      {
        Header: "Status",
        accessor: "status",
        width: 250,
      },
      {
        Header: "Added on",
        accessor: "createdAtFormatted",
        width: 250,
      },
      {
        Header: "Last Signed-in",
        accessor: "last_signed_inFormatted",
        width: 250,
      },
      {
        Header: "Action",
        width: 200,
        Cell: ({ row }: any) => {
          return !row.original.is_verified ? (
            <>
              <button
                className="resend-btn text-center"
                onClick={(e) =>
                  handleResendInvite(e, getValue(row, `original.id`, ""))
                }
              >
                <img
                  src="/images/resend.svg"
                  className="img-fluid d-block ms-2"
                />
              </button>
            </>
          ) : (
            <p className="ms-4 color_primary header_text__14">Verified</p>
          );
        },
      },
      // {
      // 	Header: "Action",
      // 	width: 200,
      // 	Cell: ({ row }: any) => {
      // 		return (
      // 			<>
      // 				<Button
      // 					color="primary"
      // 					onClick={() => handleEdit(row.original.id)}
      // 				>
      // 					Edit
      // 				</Button>
      // 				&nbsp;&nbsp;&nbsp;
      // 				<Button
      // 					color="danger"
      // 					onClick={() =>
      // 						handleDeleteFun(
      // 							row.original.id,
      // 							row.values.first_name + " " + row.values.last_name
      // 						)
      // 					}
      // 				>
      // 					Delete
      // 				</Button>
      // 			</>
      // 		);
      // 	},
      // },
    ],
    []
  );

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const [check, setCheck] = useState(false);
  const handleRemoveNavItems = () => {
    setCheck(!check);
    setSelectedIDs([]);
  };

  const [otp, setOtp] = useState("");
  const changeEmail = async () => {
    // if (getValue(request, `email`, "")) {
    //   let payload = {
    //     email: getValue(request, `email`, ""),
    //   };
    //   let resp = await clientVendorChangeEmail(payload);
    //   if (resp) {
    //     toast.success("Email Sent Successfully");
    //   }
    // } else {
    //   toast.error("Email should be mandatory");
    // }
  };

  const verifyEmail = async () => {
    // if (otp && getValue(request, `email`, "")) {
    //   try {
    //     let payload = {
    //       email: getValue(request, `email`, ""),
    //       otp: otp,
    //     };
    //     let resp = await clientVendorVerifyEmail(payload);
    //     if (resp) {
    //       toast.success("Updated Successfully");
    //     }
    //   } catch (error) {}
    // } else {
    //   toast.error("Email & OTP should be mandatory");
    // }
  };
  return (
    <DashboardLayout>
      <div className="dashboard-wrapper__header d-flex align-items-center justify-content-between">
        <h4 className="dashbaord-wrapper__header-title">Users</h4>
        <div></div>
      </div>
      <>
        <div className="admin-wrapper position-relative mt-1">
          {getValue(selectedIDs, `length`, 0) > 0 && (
            <NavigationBar
              selectedIDs={selectedIDs}
              isMarkInactiveSectionVisible={false}
              handleChangeActiveStatus={handleChangeActiveStatus}
              text={"MARK INACTIVE"}
              handleRemoveNavItems={handleRemoveNavItems}
              multi={true}
            />
          )}
          <div className="admin-wrapper position-relative">
            <section className="add-filter-wrapper add-filter-wrapper--border w-100 d-flex align-items-center justify-content-between">
              {
                <div className="add-filter-wrapper__add d-flex">
                  <a onClick={handleModal}>
                    <div className="add-filter-wrapper__add-icon d-flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24"
                        width="24"
                      >
                        <path
                          d="M11 19V13H5V11H11V5H13V11H19V13H13V19Z"
                          fill="#ffffff"
                        />
                      </svg>
                    </div>
                  </a>
                </div>
              }
              <div className="add-filter-wrapper__filter d-flex align-items-center">
                <Search
                  searchInput={search_text}
                  showSearchTextbox={showSearchTextbox}
                  toggleSearch={toggleSearch}
                  toggleSearchClose={toggleSearchClose}
                  handleChangeSearch={handleChangeSearch}
                />
              </div>
            </section>
          </div>
          {isLoading ? (
            <GearLoader />
          ) : usersList.length > 0 ? (
            getValue(props, `permissions`, []).includes("create") ? (
              <ScrollableReactTable
                columns={columns}
                data={usersList}
                getTableState={getTableState}
                rowClick={rowClick}
                check={check}
                selectedIDs={selectedIDs}
                getTrProps={getTrProps}
              />
            ) : (
              <ScrollableReactTable
                columns={columns}
                data={usersList}
                getTableState={getTableState}
                check={check}
                rowClick={rowClickEmpty}
                selectedIDs={selectedIDs}
                getTrProps={getTrProps}
              />
            )
          ) : (
            <>
              {Object.keys(params).length !== 0 ? (
                <>
                  <Notfound />
                </>
              ) : (
                <Notfound />
              )}
            </>
          )}
        </div>
        <div className="footer-wrapper d-flex justify-content-between">
          {usersList.length > 0 && (
            <img
              src="/images/building-illustration.svg"
              className="img-fluid"
            />
          )}
          {totalCount > 10 ? (
            <PaginationPage
              totalCount={totalCount}
              limit={limit}
              pageNumber={page_no}
              handleChangePagination={handleChangePagination}
              handleChangeLimit={handleChangeLimit}
            />
          ) : (
            <p className="show-rows-count mt-3 d-flex">
              Total Count:&nbsp;&nbsp;{" "}
              <p style={{ color: "rgb(13, 110, 253)" }}>{totalCount}</p>
            </p>
          )}
        </div>
        <DeleteModal
          isOpen={isDelete}
          handleModal={handleDelete}
          handleSubmit={handleDeleteFunction}
          deleteValue={deleteValue}
        />

        <Modal isOpen={isOpen1}>
          <ModalBody>
            <h3>Users</h3>
            <div className="user-details-wrapper__form">
              <div className="user-details-wrapper__form-group form-group">
                <label className="mb-1">
                  Email <span>*</span>
                </label>
                <InputField
                  inputType="TEXT"
                  placeholder="Enter Email"
                  className="users-form-control form-control w-100"
                  name="email"
                  id="email"
                  value={getValue(request, `email`, "")}
                  onChange={(e: any) => handleChangeText(e)}
                  label="Email"
                />
              </div>

              {edit && (
                <div className="add-popup-wrapper__cta-wrapper">
                  <button
                    onClick={() => changeEmail()}
                    className="add-popup-wrapper__cta-btn add-popup-wrapper__cta-btn--secondary"
                  >
                    Verify Email
                  </button>
                </div>
              )}

              <div className="user-details-wrapper__form-group form-group">
                <label>
                  OTP <span>*</span>
                </label>
                <InputField
                  inputType="TEXT"
                  placeholder="Enter OTP"
                  className="users-form-control form-control w-100"
                  name="otp"
                  id="otp"
                  value={otp}
                  onChange={(e: any) => setOtp(e)}
                  label="OTP"
                />
                {edit && (
                  <div className="add-popup-wrapper__cta-wrapper">
                    <button
                      onClick={() => verifyEmail()}
                      className="add-popup-wrapper__cta-btn add-popup-wrapper__cta-btn--secondary"
                    >
                      Verify OTP
                    </button>
                  </div>
                )}
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={isOpen}
          // toggle={isOpen}
        >
          <ModalHeader>
            <h3>Users</h3>
          </ModalHeader>
          <ModalBody className="px-4">
            <div className="user-details-wrapper__form">
              <div className="user-details-wrapper__form-group">
                <label className="mb-1">
                  Name <span>*</span>
                </label>
                <InputField
                  inputType="TEXT"
                  placeholder="Enter  Name"
                  className="users-form-control form-control"
                  name="name"
                  id="name"
                  value={getValue(request, `name`, "")}
                  onChange={(e: any) => handleChangeText(e)}
                  label="Name"
                  validator={simpleValidator}
                />
              </div>
              <div className="user-details-wrapper__form-group">
                <label className="mb-1">Mobile Number</label>
                <InputField
                  inputType="TEXT"
                  placeholder="Enter Mobile Number"
                  className="users-form-control form-control"
                  name="phone_no"
                  id="phone_no"
                  label="Mobile Number"
                  value={getValue(request, `phone_no`, "")}
                  onChange={(e: any) => {
                    setRequest({
                      ...request,
                      phone_no: handleChange(e),
                    });
                  }}
                  // validator={simpleValidator}
                />
              </div>
              <div className="user-details-wrapper__form-group">
                <label className="mb-1">Email ID</label>
                <InputField
                  inputType="EMAIL"
                  placeholder="Enter Email ID"
                  className="users-form-control form-control"
                  name="email"
                  id="email"
                  label="Email"
                  value={getValue(request, `email`, "")}
                  onChange={(e: any) => handleChangeText(e)}
                  validator={simpleValidator}
                  disabled={edit ? true : false}
                />
              </div>
              <div>
                <p className="mb-3">Super User</p>
                <div className="choicelist-wrapper custom-switch-wrapper">
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={getValue(request, `is_super_user`, false)}
                      onChange={() =>
                        setRequest({
                          ...request,
                          is_super_user: !getValue(
                            request,
                            `is_super_user`,
                            ""
                          ),
                        })
                      }
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            </div>

            <div className="mt-4"></div>
            <ModalFooter>
              <div className="add-popup-wrapper__cta-wrapper__nomargin">
                <button
                  onClick={handleModal}
                  className="add-popup-wrapper__cta-btn add-popup-wrapper__cta-btn--secondary"
                >
                  Cancel
                </button>
                {submitLoading ? (
                  <button className="add-popup-wrapper__cta-btn add-popup-wrapper__cta-btn--primary">
                    Please wait...
                  </button>
                ) : (
                  <button
                    className="add-popup-wrapper__cta-btn add-popup-wrapper__cta-btn--primary"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                )}
              </div>
            </ModalFooter>
          </ModalBody>
        </Modal>
      </>
    </DashboardLayout>
  );
};

export default AdminUsers;
